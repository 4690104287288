import { useQuery } from "@tanstack/vue-query";

export interface HurstQueryOptions<TData> {
  queryKey: any[];
  queryFn: () => Promise<TData>;
  ssr?: boolean;
  enabled?: Ref<boolean>;
  staleTime?: number;
}

export const useHurstQuery = <TData>(options: HurstQueryOptions<TData>) => {
  const enabledSsr = ref(options.ssr ?? false);

  const enabled = computed(() => {
    if (!enabledSsr.value) {
      return false;
    }

    return options.enabled?.value ?? true;
  });

  const result = useQuery<TData, Error>({
    ...options,
    enabled,
  });

  onMounted(() => {
    enabledSsr.value = true;
  });

  const isLoading = computed(() => {
    if (!enabledSsr.value) {
      return true;
    }
    return result.isLoading.value;
  });

  const isPending = computed(() => {
    if (!enabledSsr.value) {
      return true;
    }
    return result.isPending.value;
  });

  return {
    ...result,
    isLoading,
    isPending,
  };
};
